export const Appurls = {
	auth: {
		accept_supervisor_invite: '/auth/intern-supervisor-accept-invitation/',
		accept_institution_intern_invite: '/auth/institution-intern-accept-invitation/',
		accept_institutiton_admin_invite_as_head: '/auth/department-head-accept-invitation/',
		accept_institutiton_admin_invite_as_supervisor: '/auth/department-supervisor-accept-invitation/',
		password: {
			verify_otp: '/auth/validate-reset-otp/',
			create_password: '/auth/create-password/',
			change_password: '/auth/change-password/',
			reset_password: '/auth/initiate-password-reset/',
		},
		login: '/auth/login/',
		verify_account: '/auth/verify-account/',
		verify_supervisor_account: '/auth/verify-supervisor/',
		refresh_token: '/auth/token/refresh/',
	},

	coupon_code: {
		validate_code: '/payment/validate-coupon/',
		get_codes: '/payment/coupon/',
		delete_code: (id: string) => `/payment/coupon/${id}/`,
	},

	dashboard: {
		dashboard_stats: '/dashboard/stat/',
		dashboard_business_stats: '/dashboard/biz-stat/',
		dashboard_intern_stats: '/dashboard/intern-stat/',
		businesses: '/dashboard/businesses/',
		concerns: {
			intern: '/dashboard/intern-concerns/',
			startup: '/dashboard/startup-concerns/',
			respond_to_concern: (id: string | number) => `/dashboard/send-concern-msg/${id}/`,
		},
		get_approval_logs: (id: string | number) => `/dashboard/${id}/profile-approval-logs/`,
		get_businesses: (id: string | number) => `/dashboard/businesses/${id}`,
		get_institution: (id: string | number) => `/dashboard/institution/${id}`,
		interns: '/dashboard/applicants/',
		institutions: '/dashboard/institutions/',
		approve_intern: (id: string | number) => `/user/${id}/approve-intern-profile/`,
		reject_intern: (id: string | number) => `/user/${id}/reject-intern-profile/`,
		approve_startup: (id: string | number) => `/user/${id}/approve-startup-profile/`,
		reject_startup: (id: string | number) => `/user/${id}/reject-startup-profile/`,
		approve_institution: (id: string | number) => `/user/${id}/approve-institution-admin-profile/`,
		reject_institution: (id: string | number) => `/user/${id}/reject-institution-admin-profile/`,
		get_interns: (id: string | number) => `/dashboard/applicants/${id} `,
		intern_packages: '/dashboard/intern-packages/',
		get_intern_package: (id: string | number) => `/dashboard/intern-packages/${id}/`,
		startup_packages: '/dashboard/startup-packages/',
		get_startup_package: (id: string | number) => `/dashboard/startup-packages/${id}/`,
	},

	institution_admin: {
		index: '/institution/',
		user_profile: '/institution/user-institution/',
		update_profile: '/institution/update-institution-info/',
		get_student_profile: (id: string) => `institution/inst-intern-profile/${id}/`,

		department: {
			index: '/department/',
			single_department: (id: string) => `/department/${id}/`,
			activate: (id: string) => `/department/${id}/activate/`,
			deactivate: (id: string) => `/department/${id}/deactivate/`,
			active_students: '/department/signedup-students/',
			pending_students: '/department/pending-students/',

			head: {
				onboard: '/institution/onboard-dept-heads/',
				pending: '/institution/pending-dept-heads/',
				active: '/institution/signedup-dept-heads/',
				reinvite: (invite_id: string) => `/institution/${invite_id}/reinvite-dept-head/`,
				change_head: (dept_id: string) => `/department/${dept_id}/change-dept-head/`,
				get_supervisors: (dept_id: string) => `/department/${dept_id}/get-supervisors/`,
			},
		},
		stats: {
			dashboard: '/institution/institution-admin-dashboard-stat/',
			departments: '/institution/institution-admin-dept-stat/',
			department: (id: string) => `/department/${id}/stat/`,
		},
		supervisor: {
			get_detail: (id: string) => `/institution/inst-supervisor-by-id/${id}/`,
			active: '/institution/signedup-dept-supervisors/',
			pending: '/institution/pending-dept-supervisors/',
			onboard: (id: string) => `/department/${id}/add-supervisor-to-department/`,
			reinvite: (invite_id: string) => `/institution/${invite_id}/reinvite-institution-supervisor/`,
			use_stat: (id: string) => `/institution/get-stat-by-supervisor-id/${id}/`,
			my_stat: '/institution/inst-supervisor-stat/',
			get_students: (id: string) => `institution/get-students-by-supervisor-id/${id}/`,
			get_my_students: `/institution/inst-supervisor-students/`,
			get_logs: (id: string) => `/institution/inst-supervisor/${id}/logs/`,
		},
		student: {
			active: '/institution/signedup-students/',
			pending: '/institution/pending-students/',
			bulk_onboard: (id: string) => `/department/${id}/bulk-onboard-interns-department-csv/`,
			onboard: (id: string) => `/department/${id}/onboard-department-intern/`,
			reinvite: (invite_id: string) => `/institution/${invite_id}/reinvite-department-student/`,
			get_supervisor: '/institution/my-institution-supervisor/',
		},
	},

	invitation: {
		revoke: (invite_id: string) => `/invitation/${invite_id}/`,
	},

	logs: {
		use_logs: (id: string | number) => `/log/${id}/`,
	},

	messages: {
		index: '/message/',
		conversations: '/message/conversations/',
		create_room: '/message/room-id-with-other-participant/',
		read_message: (id: string | number) => `/message/${id}/read-message/`,
	},

	notifications: {
		get_notifications: '/notification/',
		bulk_update: '/notification/bulk-update-read-status/',
	},

	payment: {
		get_payments: '/payment/',
		initialize_institution_payment: '/payment/initialize-institution-stripe-checkout-session/',
		initialize_intern_payment: '/payment/initialize-intern-stripe-checkout-session/',
		initialize_startup_payment: '/payment/initialize-startup-stripe-checkout-session/',
	},

	project: {
		index: '/project/',
		single_project: (id: string | number) => `/project/${id}/`,
		add_tasks: (id: string | number) => `/project/${id}/add-tasks/`,
		get_tasks: (id: string | number) => `/project/${id}/tasks/`,
		add_resources: (id: string | number) => `/project/${id}/add-invite-resources/`,
		add_extra_resources: (id: string | number) => `/project/${id}/add-extra-resources/`,
		get_applicant_tasks: (id: string | number) => `/project/${id}/tasks/mine/`,
		get_applicant_logs: (id: string | number) => `/project/${id}/logs/mine/`,
		get_current_tasks: '/project/tasks/current/',
		get_current_logs: '/project/logs/current/',
		invite_supervisor: (id: string | number) => `/project/${id}/invite-intern-supervisor/`,
		get_supervisors: '/project/intern-supervisors/',
		get_supervisors_resources: '/project/supervisor-project-resource/',
		get_applicant_logs_for_supervisor: (projectId: string | number, internId: string | number) => `/project/${projectId}/${internId}/logs/`,
		move_resource: (id: string | number) => `/project/${id}/move-resources-to-another-project/`,
	},

	subscription: {
		intern_current_subscription: '/subscription/intern/current/',
		startup_current_subscription: '/subscription/startup/current/',
	},

	tasks: {
		assign_tasks: (id: string | number) => `task/${id}/assign/`,
		task: (id: string | number) => `task/${id}/`,
		submit_log: (id: string | number) => `/task/${id}/submit-log/`,
	},

	user: {
		index: '/user/',
		use_user: (id: string | number) => `/user/${id}/`,
		use_intern_user: '/user/intern-profile/',
		use_startup_user: '/user/startup-profile/',
		applicant: (applicantId: string | number) => `/user/applicants/${applicantId}/`,
		applicant_experience: (applicantId: string | number) => `/user/${applicantId}/applicant-experience/`,
		delete_applicant_experience: (experienceId: string | number) => `/user/experience/${experienceId}/`,
		applicants: '/user/applicants/',
		extra_applicants: '/user/extras/',
		flag_intern_as_startup: '/user/startup-raise-concern/',
		raise_concern_as_intern: '/user/intern-raise-concern/',
		resubmit_profile: (id: string | number) => `/user/${id}/resubmit-profile/`,
		update_intern: '/user/update-intern-profile/',
		update_intern_experience: '/user/experience/',
		update_startup: '/user/update-startup-profile/',
		get_active_resources: '/user/active-resources/',
		get_my_startups: `/user/my-current-startups/`,
		parse_resume: `/user/parse-resume/`,
		onboard_institution: '/user/onboard-institution-admin/',
	},
};

export const BASE_MEDIA_URL = 'https://descinder-app-bucket.s3.amazonaws.com/media/';

import { FormEvent, useRef, useState } from 'react';
import { Button, InputGroup, SelectGroup } from '../../../../ui';
import { CloseModal } from '../../../../assets/svgs';
import { Container } from './styles';
import { GeneralChangeEventType } from '../../../../types';
import { useQueryClient } from '@tanstack/react-query';
import { handleApiError } from '../../../../utilities';
import { AxiosError } from 'axios';
import { useCreateDepartment, useGetSignedUpDepartmentHeads, useNotify } from '../../../../hooks';

type AddCouponModalProp = {
	handleClose: () => void;
};

const AddCouponModal = ({ handleClose }: AddCouponModalProp) => {
	const errorRef = useRef<HTMLDivElement>(null!);
	const queryClient = useQueryClient();
	const notify = useNotify();

	const [formData, setFormData] = useState({
		name: '',
		code: '',
		phone: '',
		dept_head_name: '',
	});
	const { data: activeDepartmentHeads, isLoading: isLoadingActiveDepartmentHeads } = useGetSignedUpDepartmentHeads({
		page: 1,
		page_size: 1000,
		enabled: true,
	});

	const { mutate: createDepartment, isLoading } = useCreateDepartment();

	const handleChange: GeneralChangeEventType = (event, name, value) => {
		errorRef.current.innerText = '';

		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name as string]: value }));
	};
	const onSubmit = (event: FormEvent) => {
		event.preventDefault();

		if (formData.name.length === 0) {
			errorRef.current.innerText = 'One or more required field needs to be filled';
			return;
		}
		const deptHeadId = activeDepartmentHeads?.results?.find((head) => head.dept_head_name === formData.dept_head_name)?.id || '';

		if (!deptHeadId) {
			errorRef.current.innerText = 'Unable to submit change head at this moment';
			return;
		}

		createDepartment(
			{
				name: formData.name,
				phone: formData.phone,
				dept_head: deptHeadId,
			},
			{
				onSuccess: () => {
					notify({
						message: 'Department has been created successfully',
						status: 'success',
						toastOptions: { toastId: 'department_create_success' },
					});

					queryClient.invalidateQueries(['institution-department-list']);
					handleClose();
				},
				onError: (error) =>
					notify({ message: handleApiError(error as AxiosError), status: 'error', toastOptions: { toastId: 'department_create_error' } }),
			}
		);
	};

	return (
		<Container>
			<form onSubmit={onSubmit}>
				<div className="form-header">
					<CloseModal tabIndex={0} onClick={handleClose} />
					<h2>Create Coupon Code</h2>
					<br />
				</div>
				{/* {
					"code": "string",
					"start_date": "2024-11-06T19:17:30.278Z",
					"end_date": "2024-11-06T19:17:30.278Z",
					"user_type": "INTERN",
					"is_slot_based": true,
					"max_allowable_slot": 2147483647,
					"current_slot": 2147483647,
					"is_price_reduction": true,
					"is_percent_reduction": true,
					"is_plan_specific": true,
					"allowed_plans": [
						"startup_quarter_pack",
						""
					],
					"reduced_price": "-17.8",
					"reduced_percent": 2147483647
				} */}

				<InputGroup label="Coupon Code" name="code" onChange={handleChange} placeholder="Enter Coupon Code" value={formData.code} required />
				<div className="grouped-inputs">
					<InputGroup
						label="Department's Mobile Number"
						name="phone"
						onChange={handleChange}
						placeholder="Enter Department's Mobile Number"
						value={formData.phone}
					/>
				</div>
				<SelectGroup
					label="Department Head's Name"
					name="dept_head_name"
					onChange={handleChange}
					options={activeDepartmentHeads?.results?.filter((head) => head.department === null).map((head) => head?.dept_head_name) || []}
					loading={isLoadingActiveDepartmentHeads}
					emptyOptionsMessage={
						activeDepartmentHeads?.results
							? 'You current have no head not assigned to any department'
							: 'You currently have no signed up department head'
					}
					placeholder="Enter Head's Name"
					value={formData.dept_head_name}
					required
				/>
				<div className="error" ref={errorRef}></div>

				<Button type="submit" loading={isLoading}>
					Add Department
				</Button>
			</form>
		</Container>
	);
};
export default AddCouponModal;

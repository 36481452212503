import { useEffect, useRef } from 'react';
import { LogoDark, LogoLightColored, Logout, HamburgerMenu, CloseMenu, NotificationBell } from '../../../../assets/svgs';
import { Container } from './styles';
import { Link, NavLink, useLocation, useSearchParams } from 'react-router-dom';
import { useDecodedUser, useNotifications, useNotify, useLogout, useMessage } from '../../../../hooks';
import { Approutes, queryStrings } from '../../../../constants';
import {
	getPreviousSearchParams,
	isAdmin,
	isInstitutionAdmin,
	isIntern,
	isInternSupervisor,
	isStartUp,
	isInstitutionDepartmentHead,
	isInstitutionSupervisor,
} from '../../../../utilities';
import StartupLinks from './StartupLinks';
import InternLinks from './InternLinks';

const GeneralHeader = ({ userDetails }) => {
	const headerRef = useRef(null);
	const { pathname } = useLocation();
	const notify = useNotify();
	const user = useDecodedUser();
	const logout = useLogout();
	const { numberOfUnreadMessages } = useMessage();
	const [searchParams, setSearchParams] = useSearchParams();

	const { data: notifications } = useNotifications({ page: 1, page_size: 5, is_read: null });

	const notificationLength = notifications?.results?.filter((notification) => notification?.is_read === false)?.length || 0;

	const openMobileNav = () => headerRef.current?.classList.add('active');
	const closeMobileNav = () => headerRef.current?.classList.remove('active');

	const handleOpenNotifications = () => {
		closeMobileNav();

		let previousParams = getPreviousSearchParams(searchParams);

		previousParams = { ...previousParams, [queryStrings.notifications]: 'open' };
		setSearchParams(previousParams, { replace: true });
	};
	const handleLogout = () => {
		logout();

		notify({ message: "You've been logged out successfully", status: 'success', toastOptions: { toastId: 'logged_out' } });
	};

	useEffect(() => {
		closeMobileNav();
	}, [pathname]);

	return (
		<Container ref={headerRef} $breakAtScreen={isInstitutionAdmin(user) ? 1600 : isIntern(user) ? 1500 : undefined}>
			<div>
				<Link to={Approutes.home} className="logo-con" onClick={closeMobileNav}>
					<LogoDark />
					<LogoLightColored />
				</Link>

				<div className="control-con">
					<div className={numberOfUnreadMessages > 0 ? 'ham-con unread' : 'ham-con'}>
						<HamburgerMenu tabIndex={0} onClick={openMobileNav} />
					</div>
					<CloseMenu className="close" tabIndex={0} onClick={closeMobileNav} />
				</div>

				<ul>
					{isStartUp(user) ? (
						<StartupLinks closeMobileNav={closeMobileNav} />
					) : isIntern(user) ? (
						<InternLinks closeMobileNav={closeMobileNav} />
					) : isInternSupervisor(user) ? (
						<>
							<li>
								<NavLink to={Approutes.supervisor.resources} onClick={closeMobileNav}>
									Resources
								</NavLink>
							</li>
							<li>
								<NavLink to={Approutes.settings.index} onClick={closeMobileNav}>
									Settings
								</NavLink>
							</li>
						</>
					) : isAdmin(user) ? (
						<>
							<li>
								<NavLink to={Approutes.dashboard.index} end onClick={closeMobileNav}>
									Dashboard
								</NavLink>
							</li>
							<li>
								<NavLink to={Approutes.dashboard.businesses} onClick={closeMobileNav}>
									Businesses
								</NavLink>
							</li>
							<li>
								<NavLink to={Approutes.dashboard.interns} onClick={closeMobileNav}>
									Interns
								</NavLink>
							</li>
							<li>
								<NavLink to={Approutes.dashboard.finances} onClick={closeMobileNav}>
									Finances
								</NavLink>
							</li>
							<li>
								<NavLink to={Approutes.dashboard.concerns} onClick={closeMobileNav}>
									Concerns
								</NavLink>
							</li>
							<li>
								<NavLink to={Approutes.dashboard.coupons} onClick={closeMobileNav}>
									Coupons
								</NavLink>
							</li>
						</>
					) : isInstitutionAdmin(user) ? (
						<>
							<li>
								<NavLink to={Approutes.institution_admin.index} onClick={closeMobileNav} end>
									Dashboard
								</NavLink>
							</li>
							<li>
								<NavLink to={Approutes.institution_admin.deparments} onClick={closeMobileNav}>
									Departments
								</NavLink>
							</li>
							<li>
								<NavLink to={Approutes.institution_admin.deparment_heads} onClick={closeMobileNav}>
									Department Heads
								</NavLink>
							</li>
							<li>
								<NavLink to={Approutes.institution_admin.supervisors} onClick={closeMobileNav}>
									Supervisors
								</NavLink>
							</li>
							<li>
								<NavLink to={Approutes.institution_admin.interns} onClick={closeMobileNav}>
									Interns
								</NavLink>
							</li>
							<li>
								<NavLink to={Approutes.settings.index} onClick={closeMobileNav}>
									Settings
								</NavLink>
							</li>
						</>
					) : isInstitutionDepartmentHead(user) ? (
						<>
							<li>
								<NavLink to={Approutes.institution_department_head.index} onClick={closeMobileNav} end>
									Dashboard
								</NavLink>
							</li>
							<li>
								<NavLink to={Approutes.institution_department_head.supervisors} onClick={closeMobileNav}>
									Supervisors
								</NavLink>
							</li>
							<li>
								<NavLink to={Approutes.institution_department_head.interns} onClick={closeMobileNav}>
									Interns
								</NavLink>
							</li>
							<li>
								<NavLink to={Approutes.settings.index} onClick={closeMobileNav}>
									Settings
								</NavLink>
							</li>
						</>
					) : isInstitutionSupervisor(user) ? (
						<>
							<li>
								<NavLink to={Approutes.institution_supervisor.interns} onClick={closeMobileNav}>
									Interns
								</NavLink>
							</li>
							<li>
								<NavLink to={Approutes.settings.index} onClick={closeMobileNav}>
									Settings
								</NavLink>
							</li>
						</>
					) : null}
				</ul>

				<div className="last-section">
					<div className="bell-con" onClick={handleOpenNotifications}>
						<NotificationBell />
						{notificationLength > 0 ? (
							<span className={notificationLength > 10 ? 'max' : ''}>{notificationLength > 10 ? '10+' : notificationLength}</span>
						) : null}
					</div>

					<div className="profile-con">
						{userDetails?.profile_image ? (
							<img src={userDetails?.profile_image} alt="" />
						) : userDetails?.initials ? (
							<div className="initials">{userDetails?.initials}</div>
						) : null}

						<div>
							<b title={userDetails?.name}>{userDetails?.name}</b>
							<span>{userDetails?.role}</span>
						</div>
					</div>

					<Logout onClick={handleLogout} />
				</div>
			</div>
		</Container>
	);
};

export default GeneralHeader;

import { Container } from './styles';
import { CloseModal, PromoBg, PromoPercent } from '../../../assets/svgs';
import { Button } from '../../../ui';
import { Approutes, STARTUP_PAYMENT_PACKAGE } from '../../../constants';

const SelectStartupPackage = ({ formData, handleChange, handleClose, handleSubmit }) => {
	const handlePlanSubmit = (event) => {
		event.preventDefault();

		if (!formData.commitment?.key) {
			alert('You have to select a plan to proceed');
			return;
		}

		handleSubmit();
	};

	return (
		<Container>
			<div>
				<div className="header">
					<CloseModal tabIndex={0} onClick={handleClose} />
					<h2>Choose Refundable Package</h2>
					<p>Select your preferred pack and make payment to proceed to adding resources</p>
				</div>
				<div className="hidden"></div>

				<div className="plans">
					{STARTUP_PAYMENT_PACKAGE.map((startupPackage) => (
						<form
							key={startupPackage.key}
							onClick={() => handleChange(null, 'commitment', startupPackage)}
							onSubmit={(event) => handlePlanSubmit(event)}
							className={`${formData.commitment?.key === startupPackage.key ? 'active' : ''} ${startupPackage?.has_promo ? 'has-promo' : ''}`}
						>
							{startupPackage?.has_promo ? (
								<span className="promo">
									<PromoBg className="bg" />

									<span>
										<PromoPercent /> PROMO
									</span>
								</span>
							) : null}
							<span className="duration">{startupPackage.title}</span>

							<br />
							{startupPackage?.has_promo ? <span className="price old">£{startupPackage.price?.old}</span> : null}
							<span className="price">£{startupPackage.price.current}</span>

							<br />
							{/* {!startupPackage?.has_promo ? <span className="refund">{startupPackage.refund_rate}</span> : null} */}
							<a href={startupPackage.video_link} target="_blank" rel="noreferrer" className="video">
								Watch Video TO LEARN
							</a>
							<ul>
								{startupPackage.perks.map((perk) => (
									<li key={perk}>{perk}</li>
								))}
							</ul>
							<div className="condition">
								<input type="checkbox" required />
								<span>
									I Watched & I Accept{' '}
									<a href={Approutes.docs.terms_of_use} target="_blank" rel="noreferrer">
										Terms & Conditions
									</a>
								</span>
							</div>
							<Button>Buy {startupPackage.title}</Button>
						</form>
					))}
				</div>

				<form className="plans mobile" onSubmit={(event) => handlePlanSubmit(event, true)}>
					{STARTUP_PAYMENT_PACKAGE.map((startupPackage) => (
						<div
							key={startupPackage.key}
							className={`${formData.commitment?.key === startupPackage.key ? 'active' : ''} ${startupPackage?.has_promo ? 'has-promo' : ''}`}
							onClick={() => handleChange(null, 'commitment', startupPackage)}
						>
							{startupPackage?.has_promo ? (
								<div>
									<span className="promo">
										<PromoBg className="bg" />

										<span>
											<PromoPercent /> PROMO
										</span>
									</span>
								</div>
							) : null}
							<div className="content">
								<div>
									<span className="duration">{startupPackage.title}</span>
									<ul>
										{startupPackage.perks.map((perk) => (
											<li key={perk}>{perk}</li>
										))}
									</ul>
								</div>
								<div>
									{startupPackage?.has_promo ? <span className="price old">£{startupPackage.price?.old}</span> : null}
									<span className="price">£{startupPackage.price.current}</span>
									{!startupPackage?.has_promo ? <span className="refund">{startupPackage.refund_rate}</span> : null}
									<a href={startupPackage.video_link} target="_blank" rel="noreferrer" className="video">
										Watch Video
									</a>
									<Button type="button">select plan</Button>
								</div>
							</div>
						</div>
					))}

					<span className="condition">
						<input type="checkbox" required />
						<span>
							I Watched & I Accept{' '}
							<a href={Approutes.docs.terms_of_use} target="_blank" rel="noreferrer">
								Terms & Conditions
							</a>
						</span>
					</span>
					<Button>BUY PACKAGE</Button>
				</form>

				{/* <p className="bottom">
					Your money will be refunded if you adhere to the <Link>Terms and Conditions & Terms of Use.</Link>
				</p> */}
			</div>
		</Container>
	);
};
export default SelectStartupPackage;

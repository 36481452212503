import { CloseModal, EyeOpen } from '../../../../assets/svgs';
import { ActiveSupervisorType } from '../../../../types';
import { Container } from './styles';

type SupervisorDetailsProp = {
	supervisor: ActiveSupervisorType | undefined;
	handleClose: () => void;
	handleViewInterns: () => void;
};

const SupervisorDetails = ({ supervisor, handleClose, handleViewInterns }: SupervisorDetailsProp) => {
	return (
		<Container>
			<div>
				<div className="header">
					<CloseModal tabIndex={0} onClick={handleClose} />

					<h2>Supervisor's Information</h2>
					{/* <p>View all the information about this supervisorst</p> */}
				</div>
				<br />

				<div>
					<article>
						<div className="name-con">
							<p>Supervisor</p>
							<b>{supervisor?.dept_supervisor_name}</b>
						</div>
						<div>
							<b>Department</b>
							<p>{supervisor?.department_name}</p>
						</div>
					</article>
					{/* <article>
						<div>
							<b>First Name</b>
							<p>Adewole</p>
						</div>
						<div>
							<b>Last Name</b>
							<p>Ampitan</p>
						</div>
					</article> */}
					<article>
						<div>
							<b>Email</b>
							<p>{supervisor?.email}</p>
						</div>
						<div>
							<b>Phone number</b>
							<p>{supervisor?.dept_supervisor_phone}</p>
						</div>
					</article>
					<article>
						<div>
							<b>Supervising</b>
							<p className="intern-con">
								{supervisor?.student_count} Interns{' '}
								<button onClick={handleViewInterns}>
									<EyeOpen />
								</button>
							</p>
						</div>
						{/* <div>
							<b>School</b>
							<p>Tesside University</p>
						</div> */}
					</article>
				</div>
			</div>
		</Container>
	);
};
export default SupervisorDetails;

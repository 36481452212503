import { getDateTime } from '../../../utilities';

const ApprovalLogs = ({ status, approvalLogs }) => {
	return (
		<>
			<article>
				<div>
					<b>Approval Status</b>
					<p className={status?.toLowerCase()}>{status?.toLowerCase()?.split('_')?.join(' ')}</p>
				</div>
			</article>

			<article>
				<b>Approval Status logs</b>
				{approvalLogs?.length ? (
					<ul>
						{approvalLogs?.map((log) => (
							<li key={log?.id}>
								<b
									className={
										log?.title?.toLowerCase() === 'profile declined'
											? 'declined'
											: log?.title?.toLowerCase() === 'profile approved'
											? 'approved'
											: log?.title?.toLowerCase() === 'profile resubmitted'
											? 'pending'
											: ''
									}
								>
									{log?.title}
								</b>
								{log?.log ? <p>Reason: {log?.log}</p> : null}
								<span>
									({getDateTime(log?.created_at)} - {getDateTime(log?.created_at, null, false)})
								</span>
							</li>
						))}
					</ul>
				) : (
					<p>No logs</p>
				)}
			</article>
		</>
	);
};
export default ApprovalLogs;

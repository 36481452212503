import { useRef, useState } from 'react';
import { Container } from './styles';
import { Button, Loader, Pagination, PalletList, SearchBar } from '../../../../ui';
import {
	useGetDepartmentById,
	useGetInstitutionAdminDepartmentStats,
	useGetSignedUpInterns,
	useGetSignedUpSupervisors,
} from '../../../../hooks';
import {
	DashboardAmountPaid,
	DashboardAmountRefunded,
	DashboardBusiness,
	DashboardInterns,
	ProjectEmpty,
	EyeOpen,
	ChevronDown,
} from '../../../../assets/svgs';
import { useCallback } from 'react';
import { GeneralChangeEventType } from '../../../../types';
import { Link, useParams } from 'react-router-dom';
import { Approutes } from '../../../../constants';
import { ChangeDepartmentHeadModal, DepartmentDetails } from '../../modals';
import { ActiveInterns, ActiveSupervisors } from '../../components';

type ControlType = 'supervisors' | 'interns';

const InstitutionDepartmentDetailPage = () => {
	const pageSize = useRef(6);
	const { departmentId } = useParams();
	const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
	const [isChangeHeadModalOpen, setIsChangeHeadModalOpen] = useState(false);
	const [page, setPage] = useState(1);
	const [formData, setFormData] = useState({
		search: '',
	});
	const [control, setControl] = useState<ControlType>('supervisors');

	const { data: stats, isLoading: statsLoading } = useGetInstitutionAdminDepartmentStats({ departmentId: departmentId as string });
	const { data: department, isLoading: isLoadingDepartment, isError: isDepartmentError } = useGetDepartmentById({ departmentId });
	const {
		data: activeSupervisors,
		isLoading: isLoadingActiveSupervisors,
		isError: isActiveSupervisorError,
	} = useGetSignedUpSupervisors({ page, page_size: 10000, search: formData.search, enabled: true });
	const {
		data: activeInterns,
		isLoading: isLoadingActiveInterns,
		isError: isActiveInternsError,
	} = useGetSignedUpInterns({ page, page_size: 10000, search: formData.search, enabled: true });

	const isFetchingData = isLoadingDepartment || isLoadingActiveSupervisors || isLoadingActiveInterns;
	const isDataError = isDepartmentError || isActiveSupervisorError || isActiveInternsError;
	const result =
		control === 'supervisors'
			? activeSupervisors
			: control === 'interns'
			? activeInterns
			: {
					results: [],
					total: 0,
			  };

	const statsData = [
		{
			title: 'Students',
			body: stats?.data?.students || 0,
			icon: <DashboardBusiness />,
		},
		{
			title: 'Invite sent',
			body: stats?.data?.total_invite || 0,
			icon: <DashboardInterns />,
		},
		{
			title: 'Completed Profile',
			body: stats?.data?.supervisor || 0,
			icon: <DashboardAmountPaid />,
		},
		{
			title: 'Supervisor',
			body: stats?.data?.completed_profile || 0,
			icon: <DashboardAmountRefunded />,
		},
	];

	const handleChange: GeneralChangeEventType = useCallback((event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name as string]: value }));
	}, []);
	const handleControl = (value: ControlType) => {
		setPage(1);
		setControl(value);
		handleChange(null, 'search', '');
	};

	return (
		<>
			<Container>
				<div className="action-con">
					<Link className="back-btn" to={Approutes.institution_admin.deparments}>
						<ChevronDown /> <span>Back to Departments</span>
					</Link>
				</div>

				<div className="header">
					<div>
						<h4>{department?.name}</h4>
						<p>View all information about this department</p>
					</div>

					<Button onClick={() => setIsDetailsModalOpen(true)}>
						<EyeOpen /> Department Info
					</Button>
				</div>

				<PalletList loading={statsLoading} data={statsData} />

				<div className="table-header">
					<div className="controls">
						<button className={control === 'supervisors' ? 'active' : ''} onClick={() => handleControl('supervisors')}>
							Supervisors
						</button>
						<button className={control === 'interns' ? 'active' : ''} onClick={() => handleControl('interns')}>
							Interns
						</button>
					</div>

					<div className="filter-con">
						<SearchBar name="search" placeholder={`Search through ${control}`} handleChange={handleChange} value={formData.search} />
					</div>
				</div>

				{isFetchingData ? (
					<center>
						<Loader />
					</center>
				) : isDataError ? (
					<center>
						<div>
							<ProjectEmpty />
							<p className="error-message">Unable to fetch {control} at the moment</p>
						</div>
					</center>
				) : !result?.results || result?.results?.length === 0 ? (
					<div className="empty-state">
						<ProjectEmpty />
						<p className="error-message">No {control} to display</p>
					</div>
				) : control === 'supervisors' ? (
					<ActiveSupervisors page={page} supervisors={activeSupervisors?.results?.filter((intern) => intern?.department === departmentId)} />
				) : control === 'interns' ? (
					<ActiveInterns page={page} interns={activeInterns?.results?.filter((intern) => intern?.department_name === department?.name)} />
				) : null}

				<Pagination page={page} setPage={setPage} maxPages={Math.ceil((result?.total || 0) / pageSize.current)} disabled={isFetchingData} />
			</Container>

			{isDetailsModalOpen ? (
				<DepartmentDetails
					department={department}
					handleClose={() => setIsDetailsModalOpen(false)}
					handleChangeDepartmentHead={() => {
						setIsDetailsModalOpen(false);
						setIsChangeHeadModalOpen(true);
					}}
					handleViewSupervisors={() => {
						setIsDetailsModalOpen(false);
						handleControl('supervisors');
					}}
					handleViewInterns={() => {
						setIsDetailsModalOpen(false);
						handleControl('interns');
					}}
				/>
			) : null}
			{isChangeHeadModalOpen && department?.id ? (
				<ChangeDepartmentHeadModal departmentId={department?.id} handleClose={() => setIsChangeHeadModalOpen(false)} />
			) : null}
		</>
	);
};
export default InstitutionDepartmentDetailPage;

import { FormEvent, useRef, useState } from 'react';
import { Button, InputGroup } from '../../../../ui';
import { CloseModal } from '../../../../assets/svgs';
import { Container } from './styles';
import { GeneralChangeEventType } from '../../../../types';
import { useQueryClient } from '@tanstack/react-query';
import { handleApiError } from '../../../../utilities';
import { AxiosError } from 'axios';
import { useCreateDepartmentHead, useNotify } from '../../../../hooks';

type InviteDepartmentHeadModalProp = {
	handleClose: () => void;
};

const InviteDepartmentHeadModal = ({ handleClose }: InviteDepartmentHeadModalProp) => {
	const errorRef = useRef<HTMLDivElement>(null!);
	const queryClient = useQueryClient();
	const notify = useNotify();

	const [formData, setFormData] = useState({
		dept_head_email: '',
		dept_head_phone: '',
		dept_head_name: '',
	});
	const { mutate: createDepartmentHead, isLoading } = useCreateDepartmentHead();

	const handleChange: GeneralChangeEventType = (event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name as string]: value }));
	};
	const onSubmit = (event: FormEvent) => {
		event.preventDefault();

		if (formData.dept_head_phone.length === 0 || formData.dept_head_name.length === 0 || formData.dept_head_email.length === 0) {
			errorRef.current.innerText = 'One or more required field needs to be filled';
			return;
		}

		createDepartmentHead(
			[
				{
					email: formData.dept_head_email,
					phone: formData.dept_head_phone,
					name: formData.dept_head_name,
				},
			],
			{
				onSuccess: () => {
					notify({
						message: 'Department head has been invited successfully',
						status: 'success',
						toastOptions: { toastId: 'department_head_create_success' },
					});

					queryClient.invalidateQueries(['institution-pending-department-head-list']);
					handleClose();
				},
				onError: (error) =>
					notify({ message: handleApiError(error as AxiosError), status: 'error', toastOptions: { toastId: 'department_head_create_error' } }),
			}
		);
	};
	return (
		<Container>
			<form onSubmit={onSubmit}>
				<div className="form-header">
					<CloseModal tabIndex={0} onClick={handleClose} />
					<h2>Invite A New Head</h2>
					<br />
				</div>

				<InputGroup
					label="Department Head's Name"
					name="dept_head_name"
					variant="input"
					onChange={(event) => {
						errorRef.current.innerText = '';
						handleChange(event);
					}}
					placeholder="Enter Head's Name"
					value={formData.dept_head_name}
					required
				/>
				<InputGroup
					label="Department Head's Email"
					name="dept_head_email"
					variant="input"
					onChange={(event) => {
						errorRef.current.innerText = '';
						handleChange(event);
					}}
					placeholder="Enter Head's Email"
					value={formData.dept_head_email}
					required
				/>
				<InputGroup
					label="Department Head's Mobile Number"
					name="dept_head_phone"
					variant="input"
					onChange={(event) => {
						errorRef.current.innerText = '';
						handleChange(event);
					}}
					placeholder="Enter Head's Mobile Number"
					value={formData.dept_head_phone}
					required
				/>
				<div className="error" ref={errorRef}></div>

				<Button type="submit" loading={isLoading}>
					Invite Head
				</Button>
			</form>
		</Container>
	);
};
export default InviteDepartmentHeadModal;

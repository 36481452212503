import { useRef, useState } from 'react';
import { Container } from './styles';
import { Button, Loader, Pagination, PalletList, SearchBar } from '../../../../ui';
import {
	useGetInstitutionSupervisorStats,
	useGetDepartments,
	useGetSupervisorDetail,
	useGetInstitutionSupervisorStudents,
} from '../../../../hooks';
import {
	DashboardAmountPaid,
	DashboardAmountRefunded,
	DashboardBusiness,
	DashboardInterns,
	ProjectEmpty,
	EyeOpen,
	ChevronDown,
} from '../../../../assets/svgs';
import { useCallback } from 'react';
import { FilterValue, GeneralChangeEventType } from '../../../../types';
import { Link, useParams } from 'react-router-dom';
import { Approutes, statisticsFilters } from '../../../../constants';
import { SupervisorDetails } from '../../modals';
import { Filter } from '../../../../components';
import { ActiveInterns } from '../../components';

const InstitutionSupervisorDetailPage = () => {
	const pageSize = useRef(6);
	const { supervisorId } = useParams();
	// const { departmentId, supervisorId } = useParams();
	const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
	const [page, setPage] = useState(1);
	const [formData, setFormData] = useState({
		search: '',
		filter_stats: (() => {
			let state: FilterValue = {};
			statisticsFilters.forEach((option) => (state[option?.name] = { value: '', queryKey: option?.queryKey }));

			return state;
		})(),
	});

	const { data: supervisor } = useGetSupervisorDetail({ id: supervisorId || '' });
	const { data: stats, isLoading: statsLoading } = useGetInstitutionSupervisorStats({ id: supervisorId || '' });
	const {
		data: departments,
		isLoading: isLoadingDepartments,
		isError: isDepartmentError,
	} = useGetDepartments({ page, page_size: pageSize.current, search: formData.search, enabled: true });
	const {
		data: activeInterns,
		isLoading: isLoadingInterns,
		isError: isInternError,
	} = useGetInstitutionSupervisorStudents({ id: supervisorId as string });

	const isFetchingData = isLoadingDepartments || isLoadingInterns;
	const isDataError = isDepartmentError || isInternError;

	const result = departments || activeInterns;

	const statsData = [
		{
			title: 'Interns',
			body: stats?.data?.students || 0,
			icon: <DashboardBusiness />,
		},
		{
			title: 'Invites sent',
			body: stats?.data?.invitations || 0,
			icon: <DashboardInterns />,
		},

		{
			title: 'Approved Profiles',
			body: stats?.data?.approved || 0,
			icon: <DashboardAmountPaid />,
		},
		{
			title: 'Pending Approvals',
			body: stats?.data?.pending || 0,
			icon: <DashboardAmountRefunded />,
		},
	];

	const handleChange: GeneralChangeEventType = useCallback((event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name as string]: value }));
	}, []);

	return (
		<>
			<Container>
				<div className="action-con">
					<Link className="back-btn" to={Approutes.institution_admin.supervisors}>
						<ChevronDown /> <span>Back to Supervisors</span>
					</Link>
				</div>

				<div className="header">
					<div>
						<h4>{supervisor?.dept_supervisor_name}</h4>
						<p>Here you can view all details about this supervisor and their interns</p>
					</div>

					<Button onClick={() => setIsDetailsModalOpen(true)}>
						<EyeOpen /> Supervisor Info
					</Button>
					<Filter name="filter_stats" onChange={handleChange} value={formData.filter_stats} options={[]} />
				</div>

				<PalletList loading={statsLoading} data={statsData} />

				<div className="table-header">
					<div className="controls">
						<h3>Intern Listings</h3>
					</div>

					<div className="filter-con">
						<SearchBar name="search" placeholder={`Search through interns`} handleChange={handleChange} value={formData.search} />
					</div>
				</div>

				{isFetchingData ? (
					<center>
						<Loader />
					</center>
				) : isDataError ? (
					<center>
						<div>
							<ProjectEmpty />
							<p className="error-message">Unable to fetch interns at the moment</p>
						</div>
					</center>
				) : !result?.results || result?.results?.length === 0 ? (
					<div className="empty-state">
						<ProjectEmpty />
						<p className="error-message">No interns to display</p>
					</div>
				) : (
					<ActiveInterns page={page} interns={activeInterns?.results} />
				)}

				<Pagination page={page} setPage={setPage} maxPages={Math.ceil((result?.total || 0) / pageSize.current)} disabled={isFetchingData} />
			</Container>

			{isDetailsModalOpen ? (
				<SupervisorDetails
					supervisor={supervisor}
					handleClose={() => setIsDetailsModalOpen(false)}
					handleViewInterns={() => {
						setIsDetailsModalOpen(false);
					}}
				/>
			) : null}
		</>
	);
};
export default InstitutionSupervisorDetailPage;

import { Link, useParams } from 'react-router-dom';
import { BillingCheckMark, EducationIcon, ExperienceIcon, ProjectEmpty } from '../../../../assets/svgs';
import { Container } from './styles';
import { useDecodedUser, useInstitutionStudent } from '../../../../hooks';
import { getDateTime, getInternshipTimeLeft, isInstitutionSupervisor } from '../../../../utilities';
import { Button, Loader } from '../../../../ui';
import { Approutes } from '../../../../constants';
import { UserType } from '../../../../types';

const InstitutionInternDetailPage = () => {
	const { internId } = useParams();
	const { data: applicant, isLoading, isError } = useInstitutionStudent({ id: internId || '' });
	const user = useDecodedUser();

	return (
		<Container>
			{isLoading ? (
				<main>
					<center>
						<Loader />
					</center>
				</main>
			) : isError ? (
				<main>
					<div className="empty-state">
						<ProjectEmpty />
						<p>Unable to fetch intern's details at the moment</p>
					</div>
				</main>
			) : (
				<>
					{isInstitutionSupervisor(user as UserType) ? (
						<Link to={Approutes.institution_supervisor.use_view_intern_logs(applicant?.user)}>
							<Button>View Logs</Button>
						</Link>
					) : null}

					<main>
						<section>
							<article className="intro">
								<b>{applicant?.name}</b>
								<p>{applicant?.about}</p>
							</article>

							{applicant?.experience?.length ? (
								<article className="experiences">
									<b>Experiences</b>

									<div>
										{applicant?.experience?.map((experience) => (
											<div key={experience?.id}>
												<ExperienceIcon />

												<div>
													<p>{experience?.employer_name}</p>
													<span>
														{experience?.role} - {experience?.duration}
													</span>
												</div>
											</div>
										))}
									</div>
								</article>
							) : null}

							<article className="education">
								<b>Education</b>

								<div>
									<div>
										<EducationIcon />
										<div>
											<p>{applicant?.recent_institution}</p>
											<span>
												{applicant?.area_of_study} - {applicant?.applicant_type}
											</span>
										</div>
									</div>
								</div>
							</article>

							<article className="skills">
								<b>Interested Roles</b>

								<div>
									{applicant?.interested_role?.map((role: string) => (
										<span key={role}>{role}</span>
									))}
								</div>
							</article>

							<article className="skills">
								<b>Skills</b>

								<div>
									{applicant?.skills?.map((skill: string) => (
										<span key={skill}>{skill}</span>
									))}
								</div>
							</article>

							<article className="languages">
								<b>Languages</b>

								<div>
									<div>
										<b>English</b>
										<span>{applicant?.english_proficiency_level}</span>
									</div>
								</div>
							</article>
						</section>

						<aside>
							<section>
								<h4>Internship Information</h4>

								<div className="flexed">
									<div>
										<b>Start date</b>
										<p>{new Date() > new Date(applicant?.start_date) ? 'Ready to start' : getDateTime(applicant?.start_date, 'mm dd yyyy')}</p>
									</div>
									<div>
										<b>Internship Duration</b>
										<p>{getInternshipTimeLeft(applicant?.start_date, applicant?.end_date)}</p>
									</div>
								</div>
								<div className="flexed">
									<div>
										<b>Department</b>
										<p>{applicant?.department_name}</p>
									</div>
									<div>
										<b>Internship Status</b>
										<p className={applicant?.is_in_project ? 'positive' : 'negative'}>
											{applicant?.is_in_project ? 'In a project' : 'Not yet in a project'}
										</p>
									</div>
								</div>
								<div className="flexed">
									<div>
										<b>Completed Profile</b>
										<p className={applicant?.has_completed_profile ? 'positive' : 'negative'}>
											{applicant?.has_completed_profile ? 'Complete' : 'Incomplete'}
										</p>
									</div>
									<div></div>
									{/* <div>
									<b>Payment Status</b>
									<p className="positive">Paid</p>
								</div> */}
								</div>
							</section>

							<section>
								<h4>Document Provided</h4>

								<div>
									<p>Applicant Avatar</p>
									{applicant?.avatar ? <BillingCheckMark /> : 'x'}
								</div>
								<div>
									<p>Institution ID Card</p>
									{applicant?.id_card?.length ? <BillingCheckMark /> : 'x'}
								</div>
								<div>
									<p>Enrolment Document</p>
									{applicant?.enrollment_doc?.length ? <BillingCheckMark /> : 'x'}
								</div>
								<div>
									<p>Supplementary Document</p>
									{applicant?.supplimentary_docs?.length ? <BillingCheckMark /> : 'x'}
								</div>
							</section>
						</aside>
					</main>
				</>
			)}
		</Container>
	);
};
export default InstitutionInternDetailPage;

import { useEffect, useState } from 'react';
import { CloseModal, ProjectEmpty } from '../../../../assets/svgs';
import { PROFILE_STATUS, queryStrings } from '../../../../constants';
import { Button, Loader } from '../../../../ui';
import { Container } from './styles';
import { useSearchParams } from 'react-router-dom';
import { getPreviousSearchParams, handleApiError } from '../../../../utilities';
import {
	useApproveInstitutionProfile,
	useGetDashboardInstitution,
	useGetDashboardUserApprovalLogs,
	useNotify,
	useRejectInstitutionProfile,
} from '../../../../hooks';
import ProfileApproval from '../ProfileApproval';
import { ApprovalLogs } from '../../components';
import { InstitutionUserType } from '../../../../types';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

const InstitutionDetails = () => {
	const [isReasonModalOpen, setIsReasonModalOpen] = useState(false);
	const [control, setControl] = useState<'INSTITUTION_DETAILS' | 'APPROVAL_LOGS'>('INSTITUTION_DETAILS');
	const [approvalMode, setApprovalMode] = useState<'REJECTING' | 'APPROVING'>('REJECTING');

	const notify = useNotify();
	const queryClient = useQueryClient();
	const [searchParams, setSearchParams] = useSearchParams();

	const institutionId = searchParams.get(queryStrings.institutionId) || '';

	const { data: userType, isLoading, isError, refetch } = useGetDashboardInstitution({ institution_id: institutionId });

	const user = userType as InstitutionUserType;

	const { data: approvalLogs, isLoading: isLogsLoading, refetch: refetchLogs } = useGetDashboardUserApprovalLogs({ user_id: institutionId });

	const { mutate: approveInstitution, isLoading: isApproving } = useApproveInstitutionProfile({ user_id: institutionId });
	const { mutate: rejectInstitution, isLoading: isRejecting } = useRejectInstitutionProfile({ user_id: institutionId });

	const handleClose = () => {
		let previousParams = getPreviousSearchParams(searchParams);
		delete previousParams[queryStrings.institutionId];

		setSearchParams(previousParams, { replace: true });
	};

	const handleApprovingClick = () => {
		if (user?.institution_admin_profile_status === PROFILE_STATUS.pending) {
			notify({
				message: 'Institution is yet to complete their profile',
				status: 'error',
				toastOptions: { toastId: 'institutiom_profile_pre_rejection_failure' },
			});

			return;
		}

		setIsReasonModalOpen(true);
		setApprovalMode('APPROVING');
	};
	const handleDeclineClick = () => {
		if (user?.institution_admin_profile_status === PROFILE_STATUS.pending) {
			notify({
				message: 'Institution is yet to complete their profile',
				status: 'error',
				toastOptions: { toastId: 'institutiom_profile_pre_rejection_failure' },
			});

			return;
		}

		setIsReasonModalOpen(true);
		setApprovalMode('REJECTING');
	};

	const handleApproving = (reason: string) => {
		if (user?.institution_admin_profile_status === PROFILE_STATUS.pending) {
			notify({
				message: 'Institution is yet to complete their profile',
				status: 'error',
				toastOptions: { toastId: 'institutiom_profile_pre_rejection_failure' },
			});

			return;
		}

		approveInstitution(
			{ is_institution_profile_approved: true, reason: reason },
			{
				onSuccess: () => {
					notify({
						message: 'Institution has been approved',
						status: 'success',
						toastOptions: { toastId: 'institution_profile_approved_success' },
					});
					refetch();
					refetchLogs();

					queryClient.invalidateQueries(['dashboard-stats']);
					queryClient.invalidateQueries(['dashboard-intern-stats']);
					queryClient.invalidateQueries(['dashboard-business-stats']);
					queryClient.invalidateQueries({ queryKey: ['dashboard-users'] });

					setIsReasonModalOpen(false);
				},
				onError: (error) =>
					notify({
						message: handleApiError(error as AxiosError),
						status: 'error',
						toastOptions: { toastId: 'institution_profile_approved_failure' },
					}),
			}
		);
	};
	const handleDecline = (reason: string) => {
		if (user?.institution_admin_profile_status === PROFILE_STATUS.pending) {
			notify({
				message: 'Institution is yet to complete their profile',
				status: 'error',
				toastOptions: { toastId: 'institutiom_profile_pre_rejection_failure' },
			});

			return;
		}

		rejectInstitution(
			{
				reason: reason,
			},
			{
				onSuccess: () => {
					notify({ message: 'Rejection successful', status: 'success', toastOptions: { toastId: 'user_profile_approved_success' } });
					refetch();
					refetchLogs();
					queryClient.invalidateQueries(['dashboard-stats']);
					queryClient.invalidateQueries(['dashboard-intern-stats']);
					queryClient.invalidateQueries(['dashboard-business-stats']);
					queryClient.invalidateQueries({ queryKey: ['dashboard-users'] });

					setIsReasonModalOpen(false);
				},
				onError: (error) =>
					notify({ message: handleApiError(error as AxiosError), status: 'error', toastOptions: { toastId: 'user_profile_approved_failure' } }),
			}
		);
	};

	useEffect(() => {
		if (institutionId) return;

		let previousParams = getPreviousSearchParams(searchParams);
		delete previousParams[queryStrings.institutionId];

		setSearchParams(previousParams, { replace: true });
	}, [institutionId, searchParams, setSearchParams]);

	return (
		<>
			<Container>
				{isLoading || isLogsLoading ? (
					<center>
						<CloseModal tabIndex={0} onClick={handleClose} />

						<div>
							<Loader />
						</div>
					</center>
				) : isError ? (
					<center>
						<CloseModal tabIndex={0} onClick={handleClose} />

						<div>
							<div>
								<ProjectEmpty />
								<p>Unable to fetch institution's details at the moment</p>
							</div>
						</div>
					</center>
				) : (
					<div>
						<CloseModal tabIndex={0} onClick={handleClose} />

						<section className="header">
							<div>
								<div className="img"></div>
								<div>
									<b>Institution Name</b>
									<p>{user?.institution_name}</p>
								</div>
							</div>
							<div>
								<b>Package</b>
								<p>{user?.package_plan?.map((item) => `${item}`).join(', ') || 'N/A'}</p>
							</div>
						</section>

						<div className="nav">
							<button className={control === 'INSTITUTION_DETAILS' ? 'active' : ''} onClick={() => setControl('INSTITUTION_DETAILS')}>
								Institution Details
							</button>
							<button className={control === 'APPROVAL_LOGS' ? 'active' : ''} onClick={() => setControl('APPROVAL_LOGS')}>
								Approval Logs
							</button>
						</div>

						{control === 'INSTITUTION_DETAILS' ? (
							<>
								<article className="flexed">
									<div>
										<b>Mobile Contact</b>
										<p>{user?.contact}</p>
									</div>
									<div>
										<b>Institution Email</b>
										<p>{user?.email}</p>
									</div>
								</article>

								<article className="flexed">
									<div>
										<b>Residing Address</b>
										<p>{user?.institution_address}</p>
									</div>
									<div>
										<b>Country</b>
										<p>{user?.country}</p>
									</div>
								</article>

								<article className="flexed">
									<div>
										<b>No of Department</b>
										<p>{user?.department_count}</p>
									</div>
									<div>
										<b>No of Interns</b>
										<p>{user?.interns_count}</p>
									</div>
								</article>
							</>
						) : control === 'APPROVAL_LOGS' ? (
							<ApprovalLogs status={user?.institution_admin_profile_status} approvalLogs={approvalLogs} />
						) : null}

						<div className="btn-group">
							<Button
								variant="secondary"
								loading={isRejecting}
								disabled={user?.institution_admin_profile_status === 'DECLINED'}
								onClick={handleDeclineClick}
							>
								Decline
							</Button>
							<Button loading={isApproving} disabled={user?.institution_admin_profile_status === 'APPROVED'} onClick={handleApprovingClick}>
								Approve
							</Button>
						</div>
					</div>
				)}
			</Container>

			{isReasonModalOpen ? (
				<ProfileApproval
					mode={approvalMode}
					isSubmitting={isApproving || isRejecting}
					handleSubmit={approvalMode === 'APPROVING' ? handleApproving : handleDecline}
					handleClose={() => setIsReasonModalOpen(false)}
				/>
			) : null}
		</>
	);
};
export default InstitutionDetails;

import { useMutation, useQuery } from '@tanstack/react-query';
import { useAxiosPrivate } from '.';
import { Appurls } from '../constants';
import { CouponType, ServerGetResponse } from '../types';

export const useValidateCouponCode = ({ code, plan_package }: { code: string; plan_package: string }) => {
	const axiosPrivate = useAxiosPrivate();

	const query = useQuery({
		queryFn: () =>
			axiosPrivate.get(`${Appurls.coupon_code.validate_code}?code=${code}&package=${plan_package}`).then((response) => response?.data),
		queryKey: ['validate-coupon-code', code, plan_package],
		enabled: code ? true : false,
		retry: false,
	});

	return {
		...query,
		isLoading: query.isLoading && query.fetchStatus !== 'idle',
	};
};

export const useGetCoupons = ({ page = 1, page_size = 10, search = '' }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: (): Promise<ServerGetResponse<CouponType>> =>
			axiosPrivate
				.get(`${Appurls.coupon_code.get_codes}?page=${page}&page_size=${page_size}&search=${search}`)
				.then((response) => response.data),
		queryKey: ['dashboard-coupons', { page, page_size, search }],
	});
};

export const useDeleteCoupon = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation(({ id }: { id: string }) => axiosPrivate.delete(Appurls.coupon_code.delete_code(id)));
};

import { useRef, useState } from 'react';
import { Container } from './styles';
import { Button, Loader, Pagination } from '../../../../ui';
import { useDeleteCoupon, useGetCoupons, useNotify } from '../../../../hooks';
import { getDateTime } from '../../../../utilities';
import { INTERN_PAYMENT_PACKAGE, STARTUP_PAYMENT_PACKAGE } from '../../../../constants';
import { ButtonPlus, ProjectEmpty, TableThreeDots } from '../../../../assets/svgs';
import { AddCouponModal } from '../../modals';
import { CouponType } from '../../../../types';

const Coupons = () => {
	const pageSize = useRef(10);
	const notify = useNotify();
	const [page, setPage] = useState(1);
	const [isAddCouponModalOpen, setIsAddCouponModalOpen] = useState(false);
	const [isDeletingFor, setIsDeletingFor] = useState('');

	const { data: coupons, isLoading, isError, refetch } = useGetCoupons({ page, page_size: pageSize.current, search: '' });
	const { mutate: deleteCoupon, isLoading: isDeletingCoupon } = useDeleteCoupon();

	const getCouponStatus = (coupon: CouponType) =>
		new Date() > new Date(coupon?.end_date)
			? 'expired'
			: new Date() < new Date(coupon?.start_date)
			? 'inactive'
			: coupon?.is_slot_based && coupon?.max_allowable_slot === coupon?.current_slot
			? 'full'
			: 'active';

	const getAllowedPlans = (coupon: CouponType) => {
		let finalPlan = '';

		coupon?.allowed_plans?.forEach((plan, index) => {
			if (coupon?.user_type === 'STARTUP') {
				const value = STARTUP_PAYMENT_PACKAGE.find((paymentPackage) => paymentPackage.key === plan)?.title;

				if (value) finalPlan += index === 0 ? value : `, ${value}`;
			} else if (coupon?.user_type === 'INTERN') {
				const value = INTERN_PAYMENT_PACKAGE.commitment_package.find((paymentPackage) => paymentPackage.key === plan)?.title;

				if (value) finalPlan += index === 0 ? value : `, ${value}`;
			} else {
				finalPlan += plan;
			}
		});

		return finalPlan;
	};

	const handleDelete = (coupon: CouponType) => {
		setIsDeletingFor(coupon?.id);

		deleteCoupon(
			{ id: coupon?.id },
			{
				onSuccess: () => {
					notify({
						message: `${coupon?.code || 'Code'} has been deleted`,
						status: 'success',
						toastOptions: { toastId: `delete_code_success-${coupon?.code}` },
					});
					refetch();
					setIsDeletingFor('');
				},
			}
		);
	};

	return (
		<Container>
			<div className="header">
				<div>
					<h4>Coupons</h4>
				</div>

				{/* <Button onClick={() => setIsAddCouponModalOpen(true)}> */}
				<Button
					onClick={() =>
						notify({
							message: 'Coming Soon',
							variant: 'minor',
							toastOptions: { toastId: 'add_coupon_code', position: 'bottom-center' },
						})
					}
				>
					<ButtonPlus /> Add Code
				</Button>
			</div>

			{isLoading ? (
				<center>
					<Loader />
				</center>
			) : isError ? (
				<center>
					<div>
						<ProjectEmpty />
						<p className="error-message">Unable to fetch coupons at the moment</p>
					</div>
				</center>
			) : !coupons?.results || coupons?.results?.length === 0 ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p className="error-message">No coupons to display</p>
				</div>
			) : (
				<>
					{/* is_plan_specific */}
					{/* allowed_plans */}
					<div className="table-container">
						<table>
							<thead>
								<tr>
									<th>S/N</th>
									<th>Code</th>
									<th>User Type</th>
									<th>% OFF</th>
									<th>Amount OFF</th>
									<th>Slots left</th>
									<th>Status</th>
									<th>Plans applied to</th>
									<th>Start Date</th>
									<th>End Date</th>
									<th>Date Created</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{coupons?.results?.map((coupon, index) => (
									<tr key={coupon?.id}>
										<td>{index + 1}</td>
										<td>{coupon?.code}</td>
										<td className="type">{coupon?.user_type?.replaceAll('_', ' ')?.toLowerCase()}</td>
										<td>{coupon?.is_percent_reduction ? coupon?.reduced_percent : 0}%</td>
										<td>£ {coupon?.is_price_reduction ? coupon?.reduced_price : 0}</td>
										<td>{coupon?.is_slot_based ? `${coupon?.current_slot}/${coupon?.max_allowable_slot}` : '0/0'}</td>
										<td className={getCouponStatus(coupon)}>{getCouponStatus(coupon)}</td>
										<td>{coupon?.allowed_plans?.length ? getAllowedPlans(coupon) : 'All'}</td>
										<td>{`${getDateTime(coupon?.start_date, 'dd/mm/yyyy')}, ${getDateTime(coupon?.start_date, undefined, false)}` || 'N/A'}</td>
										<td>{`${getDateTime(coupon?.end_date, 'dd/mm/yyyy')}, ${getDateTime(coupon?.end_date, undefined, false)}` || 'N/A'}</td>
										<td>{getDateTime(coupon?.created_at, 'dd/mm/yyyy')}</td>
										<td>
											<div className="dropdown-con">
												<button>
													<TableThreeDots />
												</button>

												<ul className={index >= coupons?.results?.length - 1 ? 'top' : ''}>
													<li tabIndex={0} onClick={() => handleDelete(coupon)}>
														{isDeletingFor === coupon?.id && isDeletingCoupon ? (
															<span className="coupon-loader">
																<Loader />
															</span>
														) : (
															'Delete'
														)}
													</li>
												</ul>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{coupons?.results?.map((coupon, index) => (
						<div key={coupon?.id} className="mobile-card">
							<div className="flexed">
								<div>
									<b>Code</b>
									<p>{coupon?.code}</p>
								</div>
								<div className="action-con">
									<div className="dropdown-con">
										<button>
											<TableThreeDots />
										</button>

										<ul className={index >= coupons?.results?.length - 1 ? 'top' : ''}>
											<li tabIndex={0} onClick={() => handleDelete(coupon)}>
												{isDeletingFor === coupon?.id && isDeletingCoupon ? (
													<span className="coupon-loader">
														<Loader />
													</span>
												) : (
													'Delete'
												)}
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="flexed">
								<div>
									<b>User Type</b>
									<p className="type">{coupon?.user_type?.replaceAll('_', ' ')?.toLowerCase()}</p>
								</div>
								<div>
									<b>Slots left</b>
									<p>{coupon?.is_slot_based ? `${coupon?.current_slot}/${coupon?.max_allowable_slot}` : '0/0'}</p>
								</div>
							</div>
							<div className="flexed">
								<div>
									<b>% OFF</b>
									<p>{coupon?.is_percent_reduction ? coupon?.reduced_percent : 0}%</p>
								</div>
								<div>
									<b>Amount OFF</b>
									<p>£ {coupon?.is_price_reduction ? coupon?.reduced_price : 0}</p>
								</div>
							</div>
							<div className="flexed">
								<div>
									<b>Status</b>
									<p className={getCouponStatus(coupon)}>{getCouponStatus(coupon)}</p>
								</div>
								<div>
									<b>Date Created</b>
									<p>{getDateTime(coupon?.created_at, 'dd/mm/yyyy')}</p>
								</div>
							</div>
							<div className="flexed">
								<div>
									<b>Start Date</b>
									<p>{`${getDateTime(coupon?.start_date, 'dd/mm/yyyy')}, ${getDateTime(coupon?.start_date, undefined, false)}` || 'N/A'}</p>
								</div>
								<div>
									<b>End Date</b>
									<p>{`${getDateTime(coupon?.end_date, 'dd/mm/yyyy')}, ${getDateTime(coupon?.end_date, undefined, false)}` || 'N/A'}</p>
								</div>
							</div>
						</div>
					))}

					<Pagination page={page} setPage={setPage} maxPages={Math.ceil(coupons?.total / pageSize.current)} disabled={isLoading} />
				</>
			)}

			{isAddCouponModalOpen ? <AddCouponModal handleClose={() => setIsAddCouponModalOpen(false)} /> : null}
		</Container>
	);
};
export default Coupons;

import { useState } from 'react';
import { Button, InputGroup } from '../../../../ui';
import { CloseModal } from '../../../../assets/svgs';
import { Container } from './styles';
import { GeneralChangeEventType } from '../../../../types';

type ProfileApprovalProps = {
	isSubmitting: boolean;
	mode: 'REJECTING' | 'APPROVING';
	handleSubmit: (reason: string) => void;
	handleClose: () => void;
};

const ProfileApproval = ({ isSubmitting, handleSubmit, handleClose, mode }: ProfileApprovalProps) => {
	const [formData, setFormData] = useState({
		reason: '',
	});

	const handleChange: GeneralChangeEventType = (event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name as string]: value }));
	};

	const onSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
		event.preventDefault();

		handleSubmit(formData.reason);
	};

	return (
		<Container>
			<form onSubmit={onSubmit}>
				<div className="header">
					<CloseModal tabIndex={0} onClick={handleClose} />
					<h2>{mode === 'APPROVING' ? 'Approve' : 'Decline'} Profile</h2>
				</div>

				<InputGroup
					label={`Reason for ${mode === 'APPROVING' ? 'approving' : 'declining'}`}
					name="reason"
					onChange={handleChange}
					placeholder={`Enter your reason for ${mode === 'APPROVING' ? 'approving' : 'declining'}`}
					value={formData.reason}
					variant="textarea"
					required={mode !== 'APPROVING'}
				/>

				<Button loading={isSubmitting}>{mode === 'APPROVING' ? 'Approve' : 'Decline'} Profile</Button>
			</form>
		</Container>
	);
};
export default ProfileApproval;

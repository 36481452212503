import { useCallback, useEffect, useState } from 'react';
import { CardInformation, SelectStartupPackage, StartupPromoCode, StartupPaymentExplained } from '..';
import { useDecodedUser, useGeneralStore, useValidateCouponCode } from '../../../hooks';
import { useSearchParams } from 'react-router-dom';
import { getPreviousSearchParams, hasStartupPaid } from '../../../utilities';
import { SESSION_STORAGE_KEYS, queryStrings } from '../../../constants';
import { useQueryClient } from '@tanstack/react-query';

const StartupFlow = () => {
	const user = useDecodedUser();
	const { store } = useGeneralStore();
	const queryClient = useQueryClient();
	const [searchParams, setSearchParams] = useSearchParams();
	const [stage, setStage] = useState(1);
	const [formData, setFormData] = useState({
		commitment: {},
		beneficiary_details: '',
		account_number: '',
		sort_code: '',
		country: '',
		promo_code: '',
	});

	const {
		data: codeResponse,
		isLoading: isCodeValidating,
		isError: isCodeInvalid,
	} = useValidateCouponCode({ code: formData.promo_code, plan_package: formData.commitment?.key || '' });

	const codeMessage =
		codeResponse?.type === 'percent_reduction'
			? `${codeResponse?.reduced_percent}% Price Reduction`
			: codeResponse?.type === 'price_reduction'
			? `£${codeResponse?.reduced_price} Price Reduction`
			: '';

	const getFinalPrice = () => {
		if (codeResponse?.type === 'percent_reduction') {
			return formData.commitment?.price?.current - (formData.commitment?.price?.current * codeResponse?.reduced_percent) / 100;
		} else if (codeResponse?.type === 'price_reduction') {
			const value = formData.commitment?.price?.current - codeResponse?.reduced_price;

			return value < 0 ? 0 : value;
		}

		return formData.commitment?.price?.current;
	};

	const handleClose = useCallback(() => {
		let previousParams = getPreviousSearchParams(searchParams);

		delete previousParams[queryStrings.subscription_plan];
		sessionStorage.setItem(SESSION_STORAGE_KEYS.has_closed_subscription, 'true');

		setSearchParams(previousParams, { replace: true });
	}, [searchParams, setSearchParams]);

	const handleChange = useCallback((event, name, value) => {
		name = event?.target?.name || name || '';
		value = event?.target?.value || value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
	}, []);

	const handlePaymentDone = () => {
		queryClient.invalidateQueries({ queryKey: ['projects'] });
		queryClient.invalidateQueries({ queryKey: ['current-subscription'] });
		queryClient.invalidateQueries({ queryKey: ['payments'] });

		handleClose();
	};

	useEffect(() => {
		if (user?.startup_profile_status !== 'APPROVED' || hasStartupPaid(user) || store?.has_paid === true) {
			handleClose();
		}
	}, [user, handleClose, stage, store]);

	return stage === 1 ? (
		<SelectStartupPackage formData={formData} handleClose={handleClose} handleChange={handleChange} handleSubmit={() => setStage(3)} /> // TOBECHANGED setStage to 2 when we start collecting payment
	) : stage === 2 ? (
		<StartupPromoCode
			formData={formData}
			isCodeValidating={isCodeValidating}
			isCodeInvalid={isCodeInvalid}
			codeMessage={codeMessage}
			finalPrice={getFinalPrice()?.toFixed(2)}
			handleChange={handleChange}
			handleClose={handleClose}
			handleBack={() => setStage(1)}
			handleSubmit={() => setStage(3)}
		/>
	) : stage === 3 ? (
		<StartupPaymentExplained handleClose={handleClose} handleBack={() => setStage(2)} handleSubmit={() => setStage(4)} />
	) : stage === 4 ? (
		<CardInformation formData={formData} handleClose={handleClose} handleBack={() => setStage(3)} handleSubmit={handlePaymentDone} />
	) : null;
	// ) : stage === 4 ? (
	// <BankingInformation formData={formData} handleChange={handleChange} handleClose={handleClose} />
};
export default StartupFlow;

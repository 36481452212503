const LANDING_PAGE_URL = 'https://descinder.com';

export const Approutes = {
	complete_registration: '/complete-registration',
	dashboard: {
		index: '/dashboard',
		businesses: '/dashboard/businesses',
		concerns: '/dashboard/concerns',
		coupons: '/dashboard/coupons',
		interns: '/dashboard/interns',
		finances: '/dashboard/finances',
	},
	docs: {
		index: `${LANDING_PAGE_URL}/docs`,
		terms_of_use: `${LANDING_PAGE_URL}/docs/terms-of-use`,
		privacy_policy: `${LANDING_PAGE_URL}/docs/privacy-policy`,
		code_of_conduct: `${LANDING_PAGE_URL}/docs/code-of-conduct`,
		core_values: `${LANDING_PAGE_URL}/docs/core-values`,
		faq: `${LANDING_PAGE_URL}/docs/faq`,
		refund_policy: `${LANDING_PAGE_URL}/docs/refund-policy`,
		community_guidelines: `${LANDING_PAGE_URL}/docs/community-guidelines`,
	},
	forgot_password: '/forgot-password',
	home: '/',
	intern: {
		add_ons: '/add-ons',
		projects: {
			project_details: '/overview/details/:projectId',
			use_project_details: (id: string | number) => `/overview/details/${id}`,
		},
		tasks: {
			overview: '/overview',
			my_tasks: '/overview/my-tasks',
			my_project_tasks: '/overview/:projectId/my-tasks',
			use_my_tasks: (projectId: string | number) => `/overview/${projectId}/my-tasks`,
			task_details: '/overview/details/:projectId/:taskId',
			use_task_details: (projectId: string | number, taskId: string | number) => `/overview/details/${projectId}/${taskId}`,
			logs: '/overview/timesheet/',
			project_logs: '/overview/:projectId/timesheet/',
			use_project_logs: (projectId: string | number) => `/overview/${projectId}/timesheet/`,
		},
		supervisor: '/supervisor',
	},
	institution_admin: {
		index: '/institution/dashboard',
		deparments: '/institution/deparments',
		deparment: '/institution/deparments/:departmentId',
		use_deparment: (id: string) => `/institution/deparments/${id}`,

		deparment_heads: '/institution/deparment-heads',

		supervisors: '/institution/supervisors',
		department_supervisor: '/institution/department/:departmentId/supervisors/:supervisorId',
		use_department_supervisor: (departmentId: string, supervisorId: string) =>
			`/institution/department/${departmentId}/supervisors/${supervisorId}`,

		interns: '/institution/interns',
		intern: '/institution/interns/:internId',
		use_intern: (id: string) => `/institution/interns/${id}`,
	},
	institution_department_head: {
		index: '/department-head/dashboard',
		accept_invite: '/department-head/accept-invitation',
		supervisors: '/department-head/supervisors',

		supervisor: '/department-head/supervisors/:supervisorId',
		use_department_supervisor: (supervisorId: string) => `/department-head/supervisors/${supervisorId}`,

		interns: '/department-head/interns',
	},
	institution_supervisor: {
		index: '/department-supervisor/dashboard',
		accept_invite: '/department-supervisor/accept-invitation',

		interns: '/department-supervisor/interns',
		view_intern_logs: `/institution/interns/:applicantId/logs`,
		use_view_intern_logs: (id: string) => `/institution/interns/${id}/logs`,
	},
	institution_intern: {
		accept_invite: '/institution-intern/accept-invitation',
	},
	messages: {
		index: '/messages',
	},
	login: '/login',
	logout: '/logout',
	playground: '/playground',
	profile: '/profile',
	settings: {
		billing: '/settings/plans-and-billings',
		index: '/settings',
		help: '/settings/help',
		notifications: '/settings/notifications',
		password: '/settings/password',
	},
	signup: { index: '/signup', intern: '/signup/as-intern', institution: '/signup/as-institution', startup: '/signup/as-startup' },
	startup: {
		early_starters: '/early-starters',
		extra_picks: '/extra-picks',
		projects: {
			index: '/projects',
			create_project: '/projects/create-new-project',
			project_details: '/projects/details/:projectId',
			use_project_details: (id: string | number) => `/projects/details/${id}`,
			task_details: '/projects/details/:projectId/:taskId',
			use_task_details: (projectId: string | number, taskId: string | number) => `/projects/details/${projectId}/${taskId}`,
		},
	},
	supervisor: {
		accept_invite: '/supervisor/accept-invitation',
		resources: '/resources',
		verify_account: '/supervisor/verify-account',
		intern_timesheet: '/resources/:projectId/:internId/timesheet',
		use_intern_timesheet: (projectId: string | number, internId: string | number) => `/resources/${projectId}/${internId}/timesheet`,
	},
	welcome: '/welcome',
};

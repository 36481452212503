import styled from 'styled-components';
import { StyledButton } from '../../../../ui/Button/styles';
import { maxQuery } from '../../../../utilities';
import { HelpSearchIcon } from '../../../../assets/images';

export const Container = styled.div`
	border-radius: 0.25rem;
	background: #fff;
	box-shadow: -9px 19px 40px 0px rgba(0, 0, 0, 0.02);
	padding: 2.5rem;
	/* max-height: calc(100vh - 18rem); */
	display: flex;
	justify-content: space-between;
	background: #fff;

	.cartegories {
		width: 40%;
		display: flex;
		flex-direction: column;
		gap: 1.625rem;

		> div {
			border: 1px solid #1212120d;
			border-radius: 10px;
			/* min-height: 15.25rem; */

			h4 {
				border-bottom: 1px solid #1212120d;
				width: 90%;
				padding: 1.125rem 0 0.875rem 0;
				margin: 0 auto;
				font-size: clamp(0.875rem, 0.7361rem + 0.4444vw, 1.125rem);
				font-family: 'Urbanist';
				font-style: normal;
				font-weight: 600;

				line-height: 22px;
			}

			div {
				min-height: 244px;
				margin: 0 auto;
				/* overflow: scroll; */

				p {
					font-size: clamp(0.625rem, 0.5556rem + 0.2222vw, 0.75rem);
					border: 2px solid #f9f9f9;
					display: inline-block;
					background: #f9f9f9;
					border-radius: 100px;
					margin: 0.5rem 0.25rem;
					padding: 0.625rem 1rem;
				}
			}
		}

		.need-help {
			border-radius: 1px;
			min-height: 17.438rem;
			display: flex;
			flex-direction: column;
			align-items: center;

			svg {
				margin: 2.25rem 0 1.031rem 0;
			}

			b {
				text-align: center;
				padding-bottom: 0.625rem;
			}

			> span {
				width: 69.1%;
				padding: 0.625rem 0 1.813rem 0;
				color: rgba(187, 187, 187, 0.666667);
				font-family: 'Urbanist';
				font-style: normal;
				font-weight: 600;
				font-size: 0.875rem;
				line-height: 17px;
				text-align: center;
			}

			a {
				display: block;
				width: 80%;
				margin-bottom: 2.875rem;

				${StyledButton} {
					width: 100%;
					padding: 0.875rem 0;
					align-items: center;
					text-align: center;
					font-weight: 700;
					font-size: 1rem;
					line-height: 28px;
					color: #ffffff;

					> span {
						font-size: 1rem;
						color: #ffffff;
					}
				}
			}
		}
	}

	.questions {
		border: 1px solid #1212120d;
		width: 55%;
		display: flex;
		flex-direction: column;
		/* overflow: scroll; */

		.help-Search {
			border-bottom: 1px solid #1212120d;
			width: 90%;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			padding: 1.125rem 0 0.875rem 0rem;

			h4 {
				font-size: clamp(1.125rem, 1.2639rem + -0.4444vw, 0.875rem);
				align-self: center;
			}

			/* span{

            } */

			input[type='search'] {
				border: 1px solid #1212120d;
				width: 40%;
				outline: none;
				height: 39px;
				font-size: 1rem;
				background-image: url('${HelpSearchIcon}');
				background-position: 10px 10px;
				background-repeat: no-repeat;
				padding-left: 35px;
			}
			input[type='search']:placeholder {
				font-size: 1rem;
			}
		}

		.project-question {
			border-radius: 4px;
			width: 90%;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			margin-bottom: 1.563rem;

			> span {
				padding: 1rem 0 0.813rem 0;
				text-align: center;
				font-family: 'Urbanist';
				font-style: normal;
				font-weight: 600;
				font-size: 0.875rem;
				line-height: 17px;
				color: rgba(187, 187, 187, 0.666667);
			}

			> div {
				border: 1px solid #1212120d;
				border-radius: 4px;

				h4 {
					font-size: clamp(0.875rem, 0.9444rem + -0.2222vw, 0.75rem);
					padding: 1rem 1.375rem 1rem 0rem;
					display: flex;
					justify-content: space-between;
					font-family: 'Urbanist';
					font-style: normal;
					font-weight: 500;
					line-height: 17px;
					display: flex;
					align-items: center;
					color: #12091b;
					cursor: pointer;
				}

				p {
					font-size: clamp(0.875rem, 0.9444rem + -0.2222vw, 0.75rem);
					padding: 0 3.61rem 1rem 0rem;
					font-family: 'Urbanist';
					font-style: normal;
					font-weight: 400;
					line-height: 19px;
					color: #bbbaba;
				}

				div {
					border-bottom: 1px solid #1212120d;
					width: 90%;
					margin: 0 auto;
					border-radius: 4px;
				}
			}
		}
	}

	${maxQuery('md')} {
		display: flex;
		flex-direction: column;
		padding: 0;
		gap: 1.25rem;
		background-color: #f9fafa;
		background-color: transparent;

		.cartegories {
			width: 100%;
			min-height: 246px;
			min-height: 15.375rem;
			display: flex;
			flex-direction: column;
			gap: 1.625rem;

			> div {
				background: #ffffff;
			}

			.need-help {
				display: none;
			}
		}

		.questions {
			border: 1px solid #1212120d;
			border-radius: 4px;
			max-height: 24.313rem;
			width: 100%;
			display: flex;
			flex-direction: column;
			/* overflow: scroll; */
			background: #ffffff;

			.help-Search {
				input {
					display: none;
				}
			}
		}
	}
`;

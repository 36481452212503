import { Container } from './styles';
import { HelpCaretDown, HelpCaretUp, HelpPlus } from '../../../../assets/svgs';
import { Button } from '../../../../ui';
import { useState } from 'react';

const Help = () => {
	const [activeIndex, setActiveIndex] = useState('0-0');

	const dropdownClick = (projectIndex, questionIndex) => {
		const newIndex = activeIndex === `${projectIndex}-${questionIndex}` ? null : `${projectIndex}-${questionIndex}`;
		console.log(newIndex);
		setActiveIndex(newIndex);
	};

	return (
		<Container>
			<div className="cartegories">
				<div>
					<h4>Categories</h4>

					<div>
						{FAQ_QUESTIONS.map((project) => (
							<p key={project.project_name}>
								{project.project_name} {project.project_data.length}
							</p>
						))}
					</div>
				</div>

				<div className="need-help">
					<HelpPlus />
					<b>Need Help?</b>
					<span>Do you have suggestions or unanswered questions?</span>

					<a href="mailto:info@descinder.com">
						<Button>Ask A Question</Button>
					</a>
				</div>
			</div>

			<div className="questions">
				<div className="help-Search">
					<h4>Questions</h4>

					<input type="search" placeholder="Search" />
				</div>

				{FAQ_QUESTIONS.map((project, projectIndex) => (
					<div className="project-question" key={projectIndex}>
						<span>{project.project_name}</span>

						<div>
							{project.project_data.map((question, questionIndex) => (
								<div key={questionIndex}>
									<h4 onClick={() => dropdownClick(projectIndex, questionIndex)}>
										{question.title}{' '}
										<span onClick={() => dropdownClick(projectIndex, questionIndex)}>
											{' '}
											{activeIndex === `${projectIndex}-${questionIndex}` ? <HelpCaretUp /> : <HelpCaretDown />}
										</span>
									</h4>
									{activeIndex === `${projectIndex}-${questionIndex}` ? <p>{question.content}</p> : null}
								</div>
							))}
						</div>
					</div>
				))}
			</div>
		</Container>
	);
};
export default Help;

//  Quick Dummy Data
const FAQ_QUESTIONS = [
	{
		project_name: 'Project',
		project_data: [
			{
				title: 'How to create new project',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
			{
				title: 'What are the requirements to create new project',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
			{
				title: 'How many resource can be added to a new project',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
			{
				title: 'How many projects can be created at a time',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
			{
				title: 'How many resource can be added to a new project',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
			{
				title: 'How many projects can be created at a time',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
		],
	},

	{
		project_name: 'Resources',
		project_data: [
			{
				title: 'How to create new project',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
			{
				title: 'What are the requirements to create new project',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
			{
				title: 'How many resource can be added to a new project',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
			{
				title: 'How many projects can be created at a time',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
			{
				title: 'How many resource can be added to a new project',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
			{
				title: 'How many projects can be created at a time',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
		],
	},

	{
		project_name: 'Add Tasks',
		project_data: [
			{
				title: 'How to create new project',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
			{
				title: 'What are the requirements to create new project',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
			{
				title: 'How many resource can be added to a new project',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
			{
				title: 'How many projects can be created at a time',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
			{
				title: 'How many resource can be added to a new project',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
			{
				title: 'How many projects can be created at a time',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
		],
	},

	{
		project_name: 'Log',
		project_data: [
			{
				title: 'How to create new project',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
			{
				title: 'What are the requirements to create new project',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
			{
				title: 'How many resource can be added to a new project',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
			{
				title: 'How many projects can be created at a time',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
			{
				title: 'How many resource can be added to a new project',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
			{
				title: 'How many projects can be created at a time',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
		],
	},

	{
		project_name: 'Commiment deposit',
		project_data: [
			{
				title: 'How to create new project',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
			{
				title: 'What are the requirements to create new project',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
			{
				title: 'How many resource can be added to a new project',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
			{
				title: 'How many projects can be created at a time',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
			{
				title: 'How many resource can be added to a new project',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
			{
				title: 'How many projects can be created at a time',
				content:
					'I have experience building large, consumer-focused, health-based content sites. While much of my experience has been in the business world, I understand the social value of this sector.',
			},
		],
	},
];
